import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { accessibility, AdvancedImage, lazyload, placeholder, responsive } from '@cloudinary/vue';
import { Cloudinary } from '@cloudinary/url-gen';
export default {
  __name: 'CloudinaryImage',
  props: {
    imageName: String,
    height: Number,
    width: Number
  },
  setup(__props) {
    const props = __props;

    // Create a Cloudinary instance and set your cloud name.
    const cld = new Cloudinary({
      cloud: {
        cloudName: process.env.VUE_APP_CLOUDINARY_CLOUD_NAME,
        apiKey: process.env.VUE_APP_CLOUDINARY_API_KEY,
        apiSecret: process.env.VUE_APP_CLOUDINARY_SECRET_KEY
      }
    });
    const myImg = cld.image(props.imageName).quality('auto');
    //console.log(myImg);

    let plugins = [responsive()];
    return (_ctx, _cache) => {
      return _openBlock(), _createBlock(_unref(AdvancedImage), {
        cldImg: _unref(myImg),
        plugins: _unref(plugins)
      }, null, 8, ["cldImg", "plugins"]);
    };
  }
};